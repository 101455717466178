import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
    inputEstimate: "0",
    value: "0",
})

function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function calculateDiscountedPrice(impressions) {
    let costPerImpression = 0.049
    let totalCost = impressions * costPerImpression

    if (impressions > 75000000) {
        totalCost *= 0.7 // 30% discount
    } else if (impressions > 50000000) {
        totalCost *= 0.75 // 25% discount
    } else if (impressions > 30000000) {
        totalCost *= 0.8 // 20% discount
    } else if (impressions > 15000000) {
        totalCost *= 0.85 // 15% discount
    } else if (impressions > 10000000) {
        totalCost *= 0.9 // 10% discount
    } else if (impressions > 2000000) {
        totalCost *= 0.95 // 5% discount
    } // < 2.000.000 has no discount

    return Math.round(totalCost)
}

export function withTotalMonthlyCost(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        let impressions = parseInt(store.value.replace(/\./g, "") || 0) || 0

        // Calculate the discounted price
        let discountedPrice = calculateDiscountedPrice(impressions)

        // Format the number with custom formatter
        const formattedTotalCost = formatNumber(discountedPrice)

        return (
            <Component
                {...props}
                text={`${formattedTotalCost} kr.`}
                onValueChange={(e) => setStore({ value: e })}
            />
        )
    }
}
